import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Contact from "../../src/components/Contact";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h3>{`Hi, I'm Subhash. 👋`}</h3>
    <p>{`I have been building software systems for two decades, with a focus on architecting data-intensive, event-centric platforms from scratch.`}</p>
    <Contact mdxType="Contact" />
    <hr></hr>
    <h3>{`Why I created Ambitious Systems.`}</h3>
    <p>{`Many excellent technical books dive into aspects of building sustainable, evolutionary, data-intensive applications. But an aspiring developer starting today has no clear path to follow.`}</p>
    <p>{`I created Ambitious Systems as a guide `}<em parentName="p">{`and`}</em>{` a detailed reference to build reliable, scalable, and sustainable applications from fundamental concepts.`}</p>
    <p>{`I wanted this knowledge of good architecture to be accessible to programmers starting on their software architecture and development journey.`}</p>
    <hr></hr>
    <h3>{`Ambitious Systems is a Digital Garden.`}</h3>
    <p>{`This site is structured in the style of a Digital Garden. Content is much like Martin Fowler's `}<a parentName="p" {...{
        "href": "https://www.martinfowler.com/bliki/"
      }}>{`Bliki`}</a>{` but `}<strong parentName="p">{`accessible primarily through links`}</strong>{` and get enriched over time.`}</p>
    <p>{`If you are interested in learning about `}<strong parentName="p">{`Digital Gardens`}</strong>{`, start with `}<a parentName="p" {...{
        "href": "https://maggieappleton.com/garden-history"
      }}>{`Maggie Appleton's definitive introduction`}</a>{`.`}</p>
    <p>{`Each piece of content is to be viewed as a Note, that is alive and evolving. Notes are heavily inspired by Andy Matuschak's concept of `}<a parentName="p" {...{
        "href": "https://notes.andymatuschak.org/Evergreen_notes"
      }}>{`Evergreen notes`}</a>{`: they are atomic, concept-oriented, and densely linked.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      