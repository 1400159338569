import React from "react"
import Layout from "../components/Layout"
import About from "../../content/static/about.mdx"

const about = () => {
  return (
    <Layout>
      <div className="container sm:max-w-screen-sm mx-auto mt-10 pt-12 w-11/12">
        <h1 className="leading-none font-extrabold tracking-tight mt-10 mb-8 sm:mt-14 sm:mb-10">
          About Ambitious Systems
        </h1>
        <article className="prose text-lg">
          <About />
        </article>
      </div>
    </Layout>
  )
}

export default about
